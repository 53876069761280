import React from 'react';
import { TablePagination as Pagination } from '@groupby/ui-components';
import { useLocalization } from '@/localization';
import { tableRowsPerPageOptions } from '@/search-for-retail/constants';
import { getPaginationValues } from '@/search-for-retail/generic/pagination/pagination.utils';
export const TablePagination = ({ itemsCount, pageNumber, rowsPerPage, rowsPerPageOptions = tableRowsPerPageOptions, maxRowsPerPage = tableRowsPerPageOptions[tableRowsPerPageOptions.length - 1], onPageChange, onRowsPerPageChange, resetEditingIndexOnPageChange, isSlim = false, }) => {
    const { formatMessage: t } = useLocalization();
    const { pagesCount, startRange, endRange } = getPaginationValues({ itemsCount, pageNumber, pageSize: rowsPerPage });
    const handlePageChange = (newPageNumber) => {
        if (newPageNumber < 1 || newPageNumber > pagesCount) {
            return;
        }
        onPageChange(newPageNumber);
        resetEditingIndexOnPageChange === null || resetEditingIndexOnPageChange === void 0 ? void 0 : resetEditingIndexOnPageChange(null);
    };
    return (<Pagination pagesCount={pagesCount} currentPage={pageNumber} maxRowsPerPage={maxRowsPerPage} rowsPerPage={rowsPerPage} rowsPerPageOptions={rowsPerPageOptions} handlePageChange={handlePageChange} handleRowsPerPageChange={(newRows) => onRowsPerPageChange(newRows)} perPageLabel={t({ key: 'PAGINATION_PER_PAGE_LABEL' })} paginationCurrentRangeLabel={t({ key: 'PAGINATION_CURRENT_RANGE_LABEL', values: { startRange, endRange, total: itemsCount } })} pageLabel={t({ key: 'PAGINATION_PAGE_LABEL' })} pageCountLabel={t({ key: 'PAGINATION_COUNT_COMMON_LABEL', values: { numPages: pagesCount } })} isSlim={isSlim}/>);
};
export default TablePagination;
